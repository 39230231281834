const accountRoutes = [
	{
	  path: '/Account',
	  name: 'Account',
	  component: () => import('@/views/account/index.vue'),
		meta: {
		  title: 'Account Page',
		  navKey: 'account',
		  requiresAuth: true, // 设置需要登录才能访问
		}
	},
  {
    path: '/Purchase',
    name: 'Purchase',
    component: () => import('@/views/account/Purchase.vue'),
	meta: {
	  title: 'Purchase Page',
	  navKey: 'account',
  	  requiresAuth: true, // 设置需要登录才能访问
	}
  },
  {
    path: '/PurchaseDetail',
    name: 'PurchaseDetail',
    component: () => import('@/views/account/PurchaseDetail.vue'),
  	meta: {
  	  title: 'PurchaseDetail Page',
  	  navKey: 'account',
  	  requiresAuth: true, // 设置需要登录才能访问
  	}
  },
  {
    path: '/Address',
    name: 'Address',
    component: () => import('@/views/account/Address.vue'),
  	meta: {
  	  title: 'Address Page',
  	  navKey: 'account',
  	  requiresAuth: true, // 设置需要登录才能访问
  	}
  },
  // {
  //   path: '/register',
  //   name: 'register',
  //   component: () => import('@/views/Register.vue')
  // }
  // 更多路由...
]

export default accountRoutes