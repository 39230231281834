/**
 * 公共请求模块
 */

import request from '@/utils/request'

export const getDynamicInfo = () => {
	return request({
		url: `/clients/client`,
		version: 'v1'
	});
}
// 单店小程序获取店铺信息
export const getSingleShop = () => {
	return request({
		url: `/clients/client_category`,
		data: null,
		method: 'GET',
		version: 'v1'
	});
}

export const getAdvert = (groupName) => {
	return request({
		url: '/banners',
		data: {
			// source:"wx",
			group_name: groupName,
		},
		version: 'v1'
	});
}

export const getShops = (params) => {
	return request({
		url: '/shops',
		data: params,
		version: 'v2'
	});
}

// 猜你喜欢（只有商品详情页传shop_item_id）
export const getLike = (params) => {
	return request({
		url: '/shop_items/recommend',
		data: params,
		version: 'v2'
	});
}

// 单店小程序获取店铺信息
export const getSimpleShopDetails = (id, data) => {
	return request({
		url: `/shops/${id}`,
		data: data,
		method: 'GET',
		version: 'v2',
	});
}

export const getShopCategories =(shopId) => {
	let data = {
		shop_id: shopId
	};
	return request({
		url: `/shop_categories`,
		data: data,
		method: 'get',
		version: 'v2'
	});
}

export const getAllShopCategoryWithItems = (shopId) => {
	let data = {
		shop_id: shopId
	};
	return request({
		url: `/shop_categories/shop_categories_with_items`,
		data: data,
		method: 'get',
		version: 'v2'
	});
}

// 获取商品列表
export const getShop_items = (data) => {
	return request({
		url: `/shop_items`,
		data: data,
		method: 'GET',
		version: 'v2',
	});
}

// 获取商品详情
export const getProductDetail = (id) => {
	return request({
		url: `/shop_items/${id}`,
		data: {
			'shop_only': true
		},
		version: 'v2'
	});
}

export const getDeliveryInformation = (id) => {
	return request({
		url: `/shops/${id}/delivery_information`,
		version: 'sgshop'
	});
} 

// 添加购物车
export const addToCart = (data) => {
	return request({
		url: '/carts',
		data: data,
		method: 'POST',
		version: 'v2'
	});
} 

//新版询价接口
export const queryPrice = (params, isSlashSale) => {
	return request({
		url: `/carts/${isSlashSale ? 'slash_activity_query_price' : 'query_price'}`,
		data: params,
		version: 'v2'
	});
}

//编辑购物车
export const editCarts = (params) => {
	const cart = {
		cart: {
			shop_item_stock_id: params.shop_item_stock_id,
			count: params.count,
			preference_options: params.preference_option_ids
		}
	};
	return request({
		url: `/carts/${params.id}`,
		data: cart,
		method: 'PUT',
		version: 'v2',
	});
}

// 删除购物车
export const deleteCart = (id) => {
	//shop.items.id
	return request({
		url: `/carts/${id}`,
		method: 'DELETE',
		version: 'v2',
	});
}

export const getCarts = (params) => {
	return request({
		url: '/carts',
		data: params,
		version: 'v2',
	});
}

// 判断必买商品
export const getValidateCart = (data) => {
	let url = `/carts/validate_cart?`
	data.cart_item_ids.forEach((item, index) => {
		url += `cart_item_ids[]=${item}`
		if (index != data.cart_item_ids.length - 1) {
			url += '&'
		}
	})
	return request({
		url,
		method: 'GET',
		version: 'v2',
	});
}

// 配送信息
export const getDeliveryInformation1 = (id, data) => {
	return request({
		url: `/shops/${id}/delivery_information`,
		data,
		version: 'sgshop',
	});
} 

//结算询价
export const payQueryPrice = (params, isSlashSale) => {
	return request({
		url: `/carts/${isSlashSale ? 'slash_activity_query_price' : 'query_price'}`,
		data: params,
		version: 'v2',
	});
}

export const getRewardPoints = () => {
	return request({
		url: '/reward_point_records/summary',
		version: 'sgshop'
	});
}

// 获取地址列表
export const getAddresses = (data) => {
	return request({
		url: '/addresses',
		version: 'v1',
		data,
	});
}

// 添加新地址
export const createAddress = (data) => {
	return request({
		url: '/addresses',
		data: data,
		method: 'POST',
		version: 'v1',
	});
} 

//创建订单
export const createPayment = (params) => {
	return request({
		url: '/payments',
		data: params,
		method: 'POST',
		version: 'v2',
	});
}
export const paymentSucceed = (id) => {
	return request({
		url: `/payments/${id}/payment_succeed`,
		method: 'POST',
		version: 'v2'
	});
}

// 重新支付生成charge对象
export const generateCharge = (id, data, returnUrl) => {
	let url = `/payments/${id}/make_payment`
	if (returnUrl) {
		url += `?return_url=${returnUrl}`
	}
	return request({
		url,
		data: data,
		method: 'POST',
		version: 'v2',
	});
}

export const getPayNow = (id) => {
	return request({
		url: `/payments/${id}/paynow`,
		method: 'GET',
		version: 'v1'
	});
}

// 重新支付生成charge对象
export const getPaymentDetail = (id) => {
	return request({
		url: `/payments/${id}`,
		method: 'GET',
		version: 'v2'
	});
}

//订单列表
export const getOrderList = (data) => {
	let url = `/payments?`
	Object.keys(data).forEach((key, i) => {
		let value = data[key]
		if (key === 'delivery_methods') {
			if (value.length > 0) {
				value.forEach((item, index) => {
					url += `delivery_methods[]=${item}`
					if (index != value.length - 1) {
						url += '&'
					}
				})
			}
		} else {
			url += `${key}=${value}`
		}
		if (i != Object.keys(data).length - 1) {
			url += '&'
		}
	})

	return request({
		url,
		method: 'get',
		version: 'v2',
	});
} 

export const getPaymentsCount = (obj) => {
	return request({
		url: '/payments/payments_count',
		data: obj,
		method: 'GET',
		version: 'v2'
	});
}

// 修改地址
export const editAddress = (id, data) => {
	return request({
		url: `/addresses/${id}`,
		data: data,
		method: 'PUT',
		version: 'v1'
	});
} // 删除收货地址


export const deleteAddress = (id) => {
	return request({
		url: `/addresses/${id}`,
		data: null,
		method: 'DELETE',
		version: 'v1',
	});
}

// 获取自提店铺(shop_id=1&support_pickup=true)
export const getStores = (data) => {
	return request({
		url: `/stores`,
		data,
		method: 'get',
		version: 'sgshop'
	});
} 

export const cancelShopPayment = (id) => {
	return request({
		url: `/shop_payments/${id}/cancel`,
		method: 'POST',
		version: 'v2'
	});
}