const homeRoutes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/Index.vue'),
	meta: {
	  title: 'Home Page',
	  navKey: 'home',
	  requiresAuth: false, // 设置需要登录才能访问
	}
  },
  // {
  //   path: '/register',
  //   name: 'register',
  //   component: () => import('@/views/Register.vue')
  // }
  // 更多路由...
]

export default homeRoutes