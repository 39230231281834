const loginRoutes = [
  {
    path: '/shop/ShoppingCart',
    name: 'ShoppingCart',
    component: () => import('@/views/shop/ShoppingCart.vue'),
  	meta: {
  	  title: '购物车',
	  navKey: 'shop',
  	  requiresAuth: true, // 设置需要登录才能访问
  	}
  },
  {
    path: '/shop',
    name: 'Product',
    component: () => import('@/views/shop/Index.vue'),
	meta: {
	  title: '商品展示',
	  navKey: 'shop',
	  requiresAuth: false,
	}
  },
  {
    path: '/shop/Detail',
    name: 'ProductDetail',
    component: () => import('@/views/shop/Detail.vue'),
  	meta: {
  	  title: '商品详情',
	  navKey: 'shop',
  	  requiresAuth: false,
  	}
  },
  {
    path: '/shop/settlement',
    name: 'settlement',
    component: () => import('@/views/shop/settlement/index.vue'),
  	meta: {
  	  title: '购物车',
	  navKey: 'shop',
  	  requiresAuth: true,
  	}
  },
  {
    path: '/shop/payNow',
    name: 'PayNow',
    component: () => import('@/views/shop/settlement/payNow.vue'),
  	meta: {
  	  title: ' 支付',
  	  navKey: 'shop',
  	  requiresAuth: true,
  	}
  },
  {
    path: '/shop/settlement/payment',
    name: 'payment',
    component: () => import('@/views/shop/settlement/payment.vue'),
  	meta: {
  	  title: 'Payment',
  	  navKey: 'shop',
  	  requiresAuth: true,
  	}
  },
  {
    path: '/packageOnlineStore/pages/stripeLandingPage/index',
    name: 'StripeLandingPage',
    component: () => import('@/views/packageOnlineStore/pages/stripeLandingPage/index.vue'),
  	meta: {
  	  title: 'Payment',
  	  navKey: 'shop',
  	  requiresAuth: true,
  	}
  },
  // 更多路由...
]

export default loginRoutes