
import * as commonApi from '@/services/commonApi'

function $editCartItem(params, onCompleted) {
	this.$getCommonCarts('editCarts', params, onCompleted)
}

function $addItemToCart(params, onCompleted, autoFlag = true) {
	this.$getCommonCarts('addToCart', params, onCompleted, true , autoFlag)
}

function $deleteCartItem(id, onCompleted) {
	this.$getCommonCarts('deleteCart', id, onCompleted)
}

function $bulkDeleteCartItems(ids, onCompleted) {
	this.$getCommonCarts('deleteCarts', ids, onCompleted)
}

function $getCommonCarts (api, params, onCompleted, flag, autoFlag = true) {
	commonApi[api](params, autoFlag)
		.then(res => {
			
			this.$setUserCarts(res.data.carts)
			if (onCompleted) {
				onCompleted(flag ? res : true);
			}
		})
		.catch(() => {
			//添加失败
			if (onCompleted) {
				onCompleted(false);
			}
		})
		.finally(res => {
			if (res && res.errors) {
				onCompleted(res.errors[0])
			}
		});
}

function $setUserCarts(carts) {
	this.$store.commit('setCarts',carts)
	// this.setcartsIcon(this.carts)
}

function $getUserCartItems(onStarted, onCompleted) {

		if (onStarted) {
			onStarted();
		}
		let params = null
		
		commonApi
			.getCarts(params)
			.then(res => {
		console.log('更新购物车-----》',res)
				this.$setUserCarts(res.data.carts)

				if (onCompleted) {
					onCompleted(true);
				}
			})
			.catch( () => {

				if (onCompleted) {
					onCompleted(false);
				}

			});
}

// 导出方法
export default {
  $editCartItem,
  $addItemToCart,
  $deleteCartItem,
  $bulkDeleteCartItems,
  $getCommonCarts,
  $setUserCarts,
  $getUserCartItems
};