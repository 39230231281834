import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css'
import './styles/element-variables.scss'
// 导入包含统一注册方法的文件
import globalMethods from './utils/globalMethods.js';

Vue.use(ElementUI);

// 将文件中的方法注册为全局方法
Object.keys(globalMethods).forEach((methodName) => {
  Vue.prototype[methodName] = globalMethods[methodName];
});

Vue.config.productionTip = false

new Vue({
	router,
	render: h => h(App),
	store
}).$mount('#app')
