function getPrefixedKey(key) {
  const envPrefix = process.env.NODE_ENV === 'production' ? 'prod_' : 'dev_';
  return envPrefix + key;
}

// 同步设置本地存储
export function setLocalStorageSync(key, value, expiration) {
  const prefixedKey = getPrefixedKey(key);

  const data = {
    value,
    expiration: expiration ? Date.now() + expiration * 1000 : undefined,
  };

  localStorage.setItem(prefixedKey, JSON.stringify(data));
}

// 同步获取本地存储
export function getLocalStorageSync(key) {
  const prefixedKey = getPrefixedKey(key);

  const data = localStorage.getItem(prefixedKey);
  if (data) {
    const parsedData = JSON.parse(data);
   if (parsedData.expiration === undefined || Date.now() < parsedData.expiration) {
     return parsedData.value;
   }
    localStorage.removeItem(prefixedKey);
  }

  return null;
}

// 异步设置本地存储
export async function setLocalStorageAsync(key, value, expiration) {
  return new Promise((resolve, reject) => {
    try {
      setLocalStorageSync(key, value, expiration);
      resolve();
    } catch (error) {
      reject(error);
    }
  });
}

// 异步获取本地存储
export async function getLocalStorageAsync(key) {
  return new Promise((resolve, reject) => {
    try {
      const value = getLocalStorageSync(key);
      resolve(value);
    } catch (error) {
      reject(error);
    }
  });
}

export function removeLocalStorage(key) {
  const prefixedKey = getPrefixedKey(key);
  localStorage.removeItem(prefixedKey);
}

// Example:

// // 同步设置本地存储
// setLocalStorageSync('myKey', 'myValue', 3600); // 保存键为 'myKey' 的值为 'myValue'，过期时间为 1 小时（3600 秒）

// // 同步获取本地存储
// const value = getLocalStorageSync('myKey'); // 获取键为 'myKey' 的值

// console.log(value); // 输出 'myValue' 或 null（如果数据过期或不存在）

// // 异步设置本地存储
// setLocalStorageAsync('myKey', 'myValue', 3600)
//   .then(() => {
//     console.log('Local storage set successfully');
//   })
//   .catch((error) => {
//     console.error('Failed to set local storage:', error);
//   });

// // 异步获取本地存储
// getLocalStorageAsync('myKey')
//   .then((value) => {
//     console.log('Value:', value);
//   })
//   .catch((error) => {
//     console.error('Failed to get local storage:', error);
//   });

// // 删除本地存储
// removeLocalStorage('myKey');
