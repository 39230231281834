const loginRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/Index.vue'),
	meta: {
	  title: 'Login',
	  // requiresAuth: truenpm install vue-router@^3.5.2
	}
  },
  {
    path: '/login/ResetPassword',
    name: 'ResetPassword',
    component: () => import('@/views/login/ResetPassword.vue'),
	meta: {
	  title: 'ResetPassword',
	}
  },
  {
    path: '/login/SignUp',
    name: 'SignUp',
    component: () => import('@/views/login/SignUp.vue'),
  	meta: {
  	  title: 'SignUp',
  	}
  },
  // 更多路由...
]

export default loginRoutes