import axios from 'axios';
import * as storage from './storage'
import { Message } from 'element-ui'
import router from '@/router'


// 创建axios实例
const request = axios.create({
  // baseURL: process.env.NODE_ENV === 'production' ? 'https://singapore.fooyotravel.com' : 'https://singapore-staging.fooyotravel.com',
    baseURL: process.env.NODE_ENV === 'production' ? 'https://singapore-staging.fooyotravel.com' : 'https://singapore-staging.fooyotravel.com',
  timeout: 60000, // 设置请求超时时间，单位为毫秒
  headers: {
    'X-APP-KEY': 'Z1atkgygmbOTojjwQCQMYiWHhH4',
    'X-APP-ID': 'wx174cd81d8f570858',
    'X-PLATFORM': 'web'
  }
});

// 请求拦截器
request.interceptors.request.use(
  config => {
    
	let clientId = _getClientId();
	const user = storage.getLocalStorageSync('userInfo') || {};
	const language = storage.getLocalStorageSync('language') || 'cn'
	
	config.headers['X-ACCESS-TOKEN'] = user.access_token || ''
	config.headers['X-CLIENT-ID'] = clientId
	config.headers['X-USER-ID'] = user.id ? String(user.id) : ''
    config.headers['LOCALE'] = language
	
	if (config.method === 'get' && config.data && typeof config.data === 'object' && Object.keys(config.data).length > 0) {
		// 将参数拼接到 URL 中
		const Params = new URLSearchParams(config.data).toString();
		config.url = config.url + '?' + Params
	}
	
	config.baseURL = config.baseURL + '/' + config.version
	
    return config;
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 响应拦截器
request.interceptors.response.use(
  response => {		// 状态码为 2xx 都会进入这里
    // console.log('请求响应成功了 => ', response)
    return response;
  },
  error => {	// 超出 2xx 状态码都都执行这里
    // console.log('请求响应失败了 => ', error)
	
	if (error.response) { // 请求发出去收到响应了，但是状态码超出了 2xx 范围
	  errorOperate(error)
	  return Promise.reject(error.response);
	} else if (error.request) { // 请求发出去没有收到响应
	  Message.error('请求超时，请刷新重试')
	} else { // 在设置请求时发生了一些事情，触发了一个错误
	  Message.error(`请求失败`)
	}
	
	// 把请求失败的错误对象继续抛出，扔给上一个调用者
    return Promise.reject(error);
  }
);


function _getClientId() {
	let id = storage.getLocalStorageSync('clientId');

	if (!id) {
		const randomStr = Math.round(Math.random() * 1000000);
		const timestamp = new Date().getTime();
		id = `Wechat_Mini_${timestamp}${randomStr}`;
		storage.setLocalStorageSync('clientId', id);
	}

	return id;
}

function errorOperate(data) {
	let error = data.response
	if (error.status && !error.status.toString().startsWith('2')) {
		if (error.status.toString() == '401') {
			// 判断是否首页路由 如果是首页路由的话就不用执行以下逻辑( 避免首页的购物车等接口跳转去登录 )
			const isRequiresAuth = router.currentRoute.meta.requiresAuth || null;
			if(!isRequiresAuth && router.currentRoute.path != '/shop/Detail'){
				console.log('不需要登录的路由--------->>>>>>>>>>>',router.currentRoute)
				return
			}
			
			if (storage.getLocalStorageSync('userInfo')) {
				try {
					storage.removeLocalStorage('userInfo');
				} catch (e) {
					// error
				}
			}
			if (error.data.errors) {
				//请求不合规范
				if (error.data.errors.length > 0) {
					//服务器告知违规原因
					Message.error( error.data.errors.join('，') )
				} else {
					//服务器未告知违规原因，需要后端修改并告知违规原因
					Message.error( '服务器开小差了，请联系客服处理。' )
				}
			} else {
				//很严重，一般是返回了500.服务器有问题
				Message.error( '服务器出错了，请联系客服处理。' )
			}
			
			// 页面重定向到登录页
			router.push({path: '/login'});
		} else {
			// 可以传弹窗内容参数
			// if (autoToast) {
			// 	this.toastServerErrorMessage(res);
			// }
			if (error.data.errors && error.data.errors.length > 0) {
				//请求不合规范
				//服务器告知违规原因
				Message.error( error.data.errors.join('，') )
			} else {
				//服务器未告知违规原因，需要后端修改并告知违规原因
				Message.error( '服务器开小差了，请联系客服处理。' )
			}
		}
	}
}

export default request
