<template>
    <div>
        <div class="header">
            <div class="header_box">
                <div class="logo">
                    <img :src="shopLogo" alt="">
                </div>
                <div class="navigation">
					<router-link to="/" :class="{ active: $route.meta.navKey === 'home' }" class="item hover">Home</router-link>
					<router-link to="/shop" :class="{ active: $route.meta.navKey === 'shop' }" class="item hover">Shop</router-link>
                    <div class="item hover">About Us</div>
                    <div class="item hover">Contact</div>
                </div>
                <div class="icon">
                    <div class="item search hover" @click="showSearch">
                        <img src="@/assets/search.png" alt="">
                    </div>
                    <div class="item account hover" @click="goToLoginPage">
                        <img src="@/assets/account.png" alt="">
                    </div>
                    <el-badge :value="cartsTotal" background-color="#f8f4e9" class="item">
                      <div class="item cart hover" @click="goToCartsPage">
                          <img src="@/assets/cart.png" alt="">
                      </div>
                    </el-badge>
                </div>
            </div>
			<div class="search-box" v-if="searchShow">
				<div class="search-contant">
					<img class="search-icon" src="@/assets/search.png" alt="">
					<el-input class="search-input" @keyup.enter.native="submitForm" v-model="keywords" placeholder="Search..."></el-input>
					<img class="close-icon" @click="closeSearch" src="@/assets/close-search.svg" alt="">
				</div>
			</div>
        </div>
        <div class="header-placeholder"></div>
		<div class="mask" v-if="searchShow"></div>
    </div>
  </template>
  
<script>
import { mapState } from 'vuex';
export default {
	name: 'AppHeader',
	data() {
		return {
			shopLogo: null,
			keywords: '',
			cartsTotal: 0,
		}
	}, 
	methods: {
		goToCartsPage() {
			this.$router.push('/shop/ShoppingCart');
		},
		submitForm() {
			const targetPath = '/shop';
			const currentPath = this.$route.path;
			
			if(this.keywords.trim() != ''){
				this.$store.commit('setkeyWords',this.keywords)
				this.keywords = '';
				this.closeSearch()
				
				if (targetPath != currentPath) {
					// 目标页面与当前页面不同，进行实际的路由跳转
					this.$router.push({ path: '/shop' });
				}
			}else{
				this.$store.commit('setkeyWords',this.keywords)
				this.closeSearch()
				
				if (targetPath != currentPath) {
					// 目标页面与当前页面不同，进行实际的路由跳转
					this.$router.push({ path: '/shop' });
				}
			}
			
		},
		showSearch() {
			this.$store.commit('setSearchShow',!this.searchShow)
		},
		closeSearch() {
			this.$store.commit('setSearchShow',false)
		},
		goToLoginPage() {
			this.$router.push('/Purchase');
		},
	},
	computed: {
		...mapState(['client','carts']), // 获取 Vuex 中的 client 状态
		searchShow() {
			return this.$store.state.searchShow;
		}
	},
	watch: {
		client: {
		  immediate: true, // 在组件创建时立即执行监听回调
		  handler(newClient) {
			// 监听 C端信息的变化，并处理相应逻辑
			if (newClient) {
				this.shopLogo = newClient.avatar || newClient.background_image_url || this.$store.getters.getImageUrl + '/miniapp_static/default_img.jpg'
			}
		  },
		},
		carts: {
		  immediate: true, // 在组件创建时立即执行监听回调
		  handler(newCarts) {
			// 监听 carts信息的变化，并处理相应逻辑
			if (newCarts) {
				// 遍历对象并计算总和
				var total = 0;
				newCarts.forEach(function(obj) {
				  if (obj.shop && obj.shop.items) {
				    obj.shop.items.forEach(function(item) {
				      total += item.count; // 假设每个item有一个名为price的属性
				    });
				  }
				});
				this.cartsTotal = total
			}
		  },
		},
	},
}
</script>
  
<style lang="scss" scoped>
::v-deep .el-badge__content {
	background-color: #f8f4e9; /* 设置badge背景颜色为红色 */
	color: #11191d;
}
/deep/ .el-input__inner{
	padding: 0;
	border: none;
	font-size: 18px;
	font-weight: 500;
	color: $color-text;
	&::placeholder {
		color: $color-text;
	}
}
.header-placeholder {
	height: 80px; /* 与头部高度相等 */
}
.header {
	background-color: #fff;
	min-width: $main-width;
	overflow: hidden;
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 999;
	.header_box{
	  @include flexbox;
	  align-items: center;
	  justify-content: space-between;
	  max-width: 1264px;
	  min-width: $main-width;
	  margin: 0 auto;
	  height: 80px;
	  .hover{
		  cursor: pointer;
	  }
	  .logo{
			width: 90px;
			margin-right: 72.7px;
			display: flex;
			align-items: center;
			img{
				width: 100%;
			}
	  }
	  .navigation{
		  @include flexbox;
		  justify-content: flex-start;
		  flex: 1;
		  .item{
			  color: $color-text-light;
			  margin-right: 48px;
			  font-size: 18px;
			  font-weight: 500;
			  transition: all .2s;
			  text-decoration: none;
			  &.active{
				  color: #11191d;
				  font-weight: 600;
			  }
		  }
	  }
	  .icon{
		  @include flexbox;
		  justify-content: space-between;
		  align-items: center;			
		  width: 155.2px;
		  margin: 0 10px;
		  .item{
			  img{
				  width: 100%;
			  }
			  &.search{
				  width: 24.9px;
				  height: 24.9px;
			  }
			  &.account{
				  width: 26.3px;
				  height: 25.1px;
			  }
			  &.cart{
				  width: 29px;
				  height: 26px;
			  }
		  }
	  }
	}
	.search-box{
		width: 100%;
		border: 1px solid #c2c2c3;
		.search-contant{
			width: 100%;
			display: flex;
			max-width: 1264px;
			min-width: 1200px;
			margin: 0 auto;
			padding: 24px 0;
			align-items: center;
			.search-input{
				flex: 1;
				margin: 0 28.4px;
			}
			.search-icon{
				width: 24.9px;
				height: 24.9px;
			}
			.close-icon{
				width: 32px;
				height: 32px;
				cursor: pointer;
			}
		}
	}
}
.mask{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.3;
	background-color: #070707;
	z-index: 998;
}
</style>