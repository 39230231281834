<template>
  <div id="app">
    <AppHeader />
    <router-view />
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from '@/components/Header.vue'
import AppFooter from '@/components/Footer.vue'
import * as storage from '@/utils/storage'
import * as commonApi from '@/services/commonApi'

export default {
	name: 'App',
	components: {
		AppHeader,
		AppFooter
	},
	created() {
		// 在应用初始化时检查登录状态
		this.checkLoginStatus();
		this.getClient(); //获取小程序动态信息
		this.getShopInfo();
		this.forceUpdateCarts(); //更新购物车商品数量
	},
	methods: {
		forceUpdateCarts() {
			this.$getUserCartItems();
		},
		checkLoginStatus() {
		  // 从本地存储中获取用户信息
		  const storageUser = storage.getLocalStorageSync('userInfo');
		  if (storageUser) {
			// 如果本地存储中存在用户信息，表示用户已登录
			this.$store.dispatch('login',storageUser)
		  } else {
			// 如果本地存储中不存在用户信息，表示用户未登录
			this.$store.dispatch('logout')
		  }
		},
		
		// 获取部分动态信息(导览页-tab个人中心-客服)
		getClient() {
			const maxRetryCount = 3; // 最大重试次数
			let retryCount = 0; // 当前重试次数

			const requestClient = () => {
				commonApi
				  .getDynamicInfo()
				  .then(res => {
					// 成功获取到 client 信息
					const client = res.data.client;
					// 更新到 store
					this.$store.commit('setClient', client);
					// 发送事件通知其他组件获取最新 c 端信息
					this.$emit('updateClient', client);
				  })
				  .catch(error => {
					// 获取失败
					console.log('Failed to get client information:', error);
					if (retryCount < maxRetryCount) {
					  // 重试次数未达到最大次数，继续重新请求
					  retryCount++;
					  requestClient();
					} else {
					  // 重试次数达到最大次数，报错或采取其他处理方式
					  console.log('Failed to get client information after multiple retries');
					  // 在这里进行报错或其他处理逻辑
					}
				  });
			};

			requestClient();
		},
		
		getShopInfo() {
			commonApi.getSingleShop().then(res => {
				this.$store.commit('setSingleShopInfo',res.data)
			});
		},
	},
}
</script>

<style lang="scss">
@import "@/styles/common.scss";
* {
  box-sizing: border-box;
}
//loading遮罩层 （header的z-index为999，默认的el-loading z-index为2000，需要修改为<999）
.el-loading-mask {
  z-index: 998 !important;
}
.btn{
	transition: all .3s;
	cursor: pointer;
	user-select: none;
	&:active{
		background-color: rgba(red($color-text), green($color-text), blue($color-text), .6)!important;
	}
}
</style>
