<template>
    <div class="footer">
      <div class="footer_content">
          <div class="footer_item about">
              <div class="title">About</div>
              <div class="item">Our Story</div>
              <div class="item">News</div>
              <div class="item">Privacy Policy</div>
          </div>
          <div class="footer_item help">
              <div class="title">Help</div>
              <div class="item">FAQs</div>
              <div class="item">Contact Us</div>
          </div>
          <div class="footer_item other">
              <div class="title">Don’t Miss Out</div>
              <div class="other_content">
                  <div class="code">
                      <img src="@/assets/code.png" alt="">
                  </div>
                  <div class="other_menu">
                      <div class="menu_item">
                          <img src="@/assets/wechat.png" alt="">
                          <div class="name">WeChatID here</div>
                      </div>
                      <div class="menu_item">
                          <img src="@/assets/xhs.png" alt="">
                          <div class="name">Mori.Mori</div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="footer_info">
          <div class="footer_info_content">
              <div class="logo">
                  <img src="@/assets/fooyo_logo_white.png" alt="">
              </div>
              <div class="filings">© 2023 Powered by Fooyo Pte. Ltd.</div>
          </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AppFooter'
  }
  </script>
  
  <style lang="scss" scoped>
  .footer{
      background-color: #fff;
      min-width: 1192px;;
      .footer_content{
          width: 1192px;
          margin: 0 auto;
          @include flexbox;
          justify-content: space-between;
          padding: 43px 0 29px;
          .footer_item{
              display: flex;
              flex-direction: column;
              .title{
                  font-size: 14px;
                  font-weight: 500;
                  margin-bottom: 30px;
                  padding: 0 12px;
              }
              .item{
                  font-size: 12px;
                  font-weight: normal;
                  margin-bottom: 20px;  
                  line-height: normal;
                  padding: 0 12px;
              }
          }
          .footer_item.other{
              .other_content{
                  @include flexbox;
                  align-items: center;
                  .code{
                      width: 155px;
                      height: 155px;
                      margin-right: 44px;
                      img{
                          width: 100%;
                      }
                  }
                  .other_menu{
                      @include flexbox;
                      flex-direction: column;
                      .menu_item{
                          margin-bottom: 18.5px;
                          @include flexbox;
                          align-items: center;
                          img{
                              width: 24px;
                              height: 24px;
                              margin-right: 10px;
                          }
                          .name{
                              font-size: 12px;
                              font-weight: 500;
                          }
                      }
                  }
              }
          }
      }
      .footer_info{
          background-color: #000;
          .footer_info_content{
              width: 1192px;
              margin: 0 auto;
              @include flexbox;
              align-items: center;
              height: 80px;
              .logo{
                  width: 99px;
                  height: 26px;
                  margin-right: 21px;
                  img{
                      width: 100%;
                      height: 100%;
                  }
              }
              .filings{
                  font-family: Raleway;
                  font-size: 12px;
                  font-weight: 500;
                  color: #fff;
              }
          }
      }
  }
  
  
  </style>
  