// store.js

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null, // 用户信息，默认为空
    isLoggedIn: false ,// 登录状态，默认为未登录
	redirectPath: '', // 保存跳转登录前的页面路径
	client: null, //c端信息
	imageUrl: null,
	single_shop_info: null, //单店信息
	urrencySymbol: 'S$', //新加坡元
	searchShow: false, //搜索框
	keyWords: '', //搜索内容
	deliveryType: 1, //1:配送， 2: 自提 3: 扫码点餐
	carts: null,
	orderType: null,
	// stripeKey: process.env.NODE_ENV === 'production' ? 'pk_live_51MdVmLBBGVvqQSr9fBfwlOry3a8yScXZAamfIet7RIOqLlHCzTF4zszoJgJXAYwqx4Ha7ctMNRyrHDvBVJ8qymib000G9FL5W6' : 'pk_test_51MdVmLBBGVvqQSr9qRmm2GkJDFIQY4SqhCosWupbNvCUKfPQdMOiYM0KXiVv3X1BKqjPULnn7R2wG20KoT7M2OdG00qvaDQxJV',
	stripeKey: process.env.NODE_ENV === 'production' ? 'pk_test_51MdVmLBBGVvqQSr9qRmm2GkJDFIQY4SqhCosWupbNvCUKfPQdMOiYM0KXiVv3X1BKqjPULnn7R2wG20KoT7M2OdG00qvaDQxJV' : 'pk_test_51MdVmLBBGVvqQSr9qRmm2GkJDFIQY4SqhCosWupbNvCUKfPQdMOiYM0KXiVv3X1BKqjPULnn7R2wG20KoT7M2OdG00qvaDQxJV',
  },
  mutations: {
	setOrderType(state, orderType) {
		state.orderType = orderType;
	},
	setCarts(state, carts) {
		state.carts = carts;
	},
	setDeliveryType(state, deliveryType) {
		state.deliveryType = deliveryType;
	},
	setSingleShopInfo(state, single_shop_info) {
		state.single_shop_info = single_shop_info;
	},
	setkeyWords(state, keyWords) {
		state.keyWords = keyWords;
	},
	setSearchShow(state, searchShow) {
		state.searchShow = searchShow;
	},
	setClient(state, client) {
		state.client = client;
	},
	setUser(state, user) {
		state.user = user;
	},
	setLoggedIn(state, isLoggedIn) {
		state.isLoggedIn = isLoggedIn;
	},
	clearUser(state) {
		state.user = null;
	},
	clearLoggedIn(state) {
		state.isLoggedIn = false;
	},
	saveRedirectPath(state, path) {
		state.redirectPath = path;
	},
	clearRedirectPath(state) {
		state.redirectPath = '';
	},
  },
  actions: {
    login({ commit }, user) {
      // 在登录成功后调用该 action，将用户信息保存到 store
      commit('setUser', user);
      commit('setLoggedIn', true);
    },
    logout({ commit }) {
      // 在登出操作中调用该 action，清除用户信息并设置登录状态为未登录
      commit('clearUser');
      commit('clearLoggedIn');
    }
  },
  getters: {
    getUser: state => state.user,
    isLoggedIn: state => state.isLoggedIn,
    getClient: state => state.client,
	getImageUrl: state => state.imageUrl,
	getSingleShopInfo: state => state.single_shop_info,
    getSymbol: state => state.urrencySymbol,
    getStripeKey: state => state.stripeKey,
  }
});
