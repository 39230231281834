// src/router/index.js
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import * as storage from '@/utils/storage'

Vue.use(VueRouter)

// 自动导入modules中的路由
const routesContext = require.context("./modules", false, /\.js$/);
const routes = routesContext
  .keys()
  .map((modulePath) => {
	// 错误处理代码，以便在有错误的情况下不中断整个路由的导入
	// 这里先判断导出的 default 属性是数组还是单个路由对象，如果是数组则直接返回，否则将其包装成一个数组。最后用 reduce 将所有路由合并成一个数组.
	// 这样即使有某个路由模块导出的不是合法的路由配置，也不会中断整个路由的导入。
    const routeModule = routesContext(modulePath);
    if (routeModule.default instanceof Array) {
      return routeModule.default;
    } else if (routeModule.default instanceof Object) {
      return [routeModule.default];
    } else {
      throw new Error(`Invalid route module: ${modulePath}`);
    }
  })
  .reduce((acc, val) => acc.concat(val), []);
  
  
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
	// 关闭搜索弹窗
	if(store.state.searchShow){
		store.commit('setSearchShow',false)
	}
  if (to.meta.requiresAuth) {
    // 检查是否已登录
    if (isUserLoggedIn()) {
      next();
    } else {
      // 未登录，保存跳转前的页面路径并跳转到登录页面
      if (to.path !== '/login') {
        store.commit('saveRedirectPath', to.fullPath);
        next('/login');
      } else {
        next(); // 当前路径已经是登录页面，直接放行
      }
    }
  } else {
    // 不需要登录，直接跳转
    next();
  }
});

//	跳转路由使页面回到顶部
router.afterEach(() => {
  window.scrollTo(0, 0);
});

function isUserLoggedIn () {
	if(store.getters.isLoggedIn){
		return true
	}else{
		const userInfo = storage.getLocalStorageSync('userInfo');
		if (userInfo) {
		  // 本地存在登录信息，表示用户已登录
		  return true;
		} else {
		  // 本地不存在登录信息，表示用户未登录
		  return false;
		}
	}
}

export default router
